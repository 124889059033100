import React, { createContext, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const auth = firebase.auth;
/**
 * This provider is created
 * to access user in whole app
 */

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login: async (email, password) => {
          try {
            await auth().signInWithEmailAndPassword(email, password);
          } catch (e) {
            if (e.code === 'auth/wrong-password') {
              alert('The password is invalid');
            } else {
              alert(e.message);
            }
            console.log(e);
          }
        },
        forgotPassword: async (email) => {
          try {
            await auth().sendPasswordResetEmail(email);
          } catch (e) {
            alert(e.message);
            console.log(e);
          }
        },
        register: async (email, password) => {
          try {
            await auth().createUserWithEmailAndPassword(email, password);
          } catch (e) {
            alert(e.message);
            console.log(e);
          }
        },
        logout: async () => {
          try {
            await auth().signOut();
          } catch (e) {
            console.error(e);
          }
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
