const cdnLessons = {
  "items": [
  {snippet:{host:'cdn', title: "Intro", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FIntro.jpg?alt=media&token=fe54df2a-1f21-434c-b43d-1f40055d3c4b", free: "true", id: "1", url: "https://cdn.berimbaula.com/sdiv_live/Intro.mp4"}
  },{snippet:{host:'cdn', title: "Berimbau 101", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FBerimbau%20101.jpg?alt=media&token=a41d8b8a-c905-426d-b543-8f873406c46c" , free: "false", id: "2", url: "https://cdn.berimbaula.com/sdiv_live%2FBerimbau%20101.mp4"}
  },{snippet:{host:'cdn', title: "Berimbau Notes", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FBerimbau%20Notes.jpg?alt=media&token=aa4b2898-1d0a-4d52-aeba-6a51d16d9277", free: "true", id: "3", url: "https://cdn.berimbaula.com/sdiv_live%2FBerimbau%20Notes.mp4"}
  },{snippet:{host:'cdn', title: "Exercise 1", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FExercise%201.jpg?alt=media&token=769b23b6-ed85-46ae-b125-f81d7c07296e", free: "true", id: "4", url: "https://cdn.berimbaula.com/sdiv_live%2FExercise%201.mp4"}
  },{snippet:{host:'cdn', title: "Exercise 2", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FExercise%202.jpg?alt=media&token=75fbced4-c74b-46db-a8e0-4d886e42a642", free: "false", id: "5", url: "https://cdn.berimbaula.com/sdiv_live%2FExercise%202.mp4"}
  },{snippet:{host:'cdn', title: "Exercise 3", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FExercise%203.jpg?alt=media&token=a378d2cc-4b5c-4091-a812-1feca3dfcdb6", free: "false", id: "6", url: "https://cdn.berimbaula.com/sdiv_live%2FExercise%203.mp4"}
  },{snippet:{host:'cdn', title: "Exercise 4", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FExercise%204.jpg?alt=media&token=f28e4695-6710-4fd6-9ce5-9511433862fb", free: "false", id: "7", url: "https://cdn.berimbaula.com/sdiv_live%2FExercise%204.mp4"}
  },{snippet:{host:'cdn', title: "Exercise 5", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FExercise%205.jpg?alt=media&token=6df5a849-4833-492b-9ea0-965597fcc33c", free: "false", id: "8", url: "https://cdn.berimbaula.com/sdiv_live%2FExercise%205.mp4"}
  },{snippet:{host:'cdn', title: "Toque de Angola", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FToque%20de%20Angola.jpg?alt=media&token=9458e6e1-dfe2-47e5-b018-c08b9a9b9473", free: "false", id: "9", url: "https://cdn.berimbaula.com/sdiv_live%2FToque%20de%20Angola.mp4"}
  },{snippet:{host:'cdn', title: "Angola - Variations 1_2", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FAngola%20-%20Variations%201_2.jpg?alt=media&token=ade25f42-4ecb-4c82-96df-92981e0bf6cc", free: "true", id: "10", url: "https://cdn.berimbaula.com/sdiv_live%2FAngola%20-%20Variations%201_2.mp4"}
  },{snippet:{host:'cdn', title: "Angola - Variation 3", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FAngola%20-%20Variation%203.jpg?alt=media&token=795874f4-ec44-4b29-85e3-eda00a0193a0", free: "false", id: "11", url: "https://cdn.berimbaula.com/sdiv_live%2FAngola%20-%20Variation%203.mp4"}
  },{snippet:{host:'cdn', title: "Angola - Variations 4_5", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FAngola%20-%20Variations%204_5.jpg?alt=media&token=a9d68d9a-7266-4740-b3bd-38db421292ce", free: "false", id: "12", url: "https://cdn.berimbaula.com/sdiv_live%2FAngola%20-%20Variations%204_5.mp4"}
  },{snippet:{host:'cdn', title: "Angola - Variations 5_6", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FAngola%20-%20Variations%205_6.jpg?alt=media&token=66e5eb3d-03e1-4834-9468-d434df19103d", free: "false", id: "13", url: "https://cdn.berimbaula.com/sdiv_live%2FAngola%20-%20Variations%205_6.mp4"}
  },{snippet:{host:'cdn', title: "Angola - Variation 7", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FAngola%20-%20Variation%207.jpg?alt=media&token=1633869a-df41-4c53-8127-085a0a80e07f", free: "false", id: "14", url: "https://cdn.berimbaula.com/sdiv_live%2FAngola%20-%20Variation%207.mp4"}
  },{snippet:{host:'cdn', title: "Angola - Variation 8", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FAngola%20-%20Variation%208.jpg?alt=media&token=fbb3af75-a19f-46df-aa2f-b06ad111339e", free: "false", id: "15", url: "https://cdn.berimbaula.com/sdiv_live%2FAngola%20-%20Variation%208.mp4"}
  },{snippet:{host:'cdn', title: "Toque São Bento Pequeno", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FToque%20Sa%CC%83o%20Bento%20Pequeno.jpg?alt=media&token=2d865445-48ff-4b44-b1e0-14ca38a8a9ad", free: "false", id: "16", url: "https://cdn.berimbaula.com/sdiv_live%2FToque%20Sa%CC%83o%20Bento%20Pequeno.mp4"}
  },{snippet:{host:'cdn', title: "SBP - Variations 9_10", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FSBP%20-%20Variations%209_10.jpg?alt=media&token=0f9ffabe-a364-45a7-b4c5-f08857f58806", free: "false", id: "17", url: "https://cdn.berimbaula.com/sdiv_live%2FSBP%20-%20Variations%209_10.mp4"}
  },{snippet:{host:'cdn', title: "SBP - Variations 11_12", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FSBP%20-%20Variations%2011_12.jpg?alt=media&token=376fb33d-ccae-4537-bf3f-5ec88fa666e8", free: "false", id: "18", url: "https://cdn.berimbaula.com/sdiv_live%2FSBP%20-%20Variations%2011_12.mp4"}
  },{snippet:{host:'cdn', title: "SBP - Variation 13", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FSBP%20-%20Variation%2013.jpg?alt=media&token=b6c901c9-0292-4f59-a938-ae9b440a169c", free: "false", id: "19", url: "https://cdn.berimbaula.com/sdiv_live%2FSBP%20-%20Variation%2013.mp4"}
  },{snippet:{host:'cdn', title: "Toque São Bento Grande de Angola", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FToque%20Sa%CC%83o%20Bento%20Grande%20de%20Angola.jpg?alt=media&token=47759ff2-efad-47a4-85e5-50f10579d861", free: "false", id: "20", url: "https://cdn.berimbaula.com/sdiv_live%2FToque%20Sa%CC%83o%20Bento%20Grande%20de%20Angola.mp4"}
  },{snippet:{host:'cdn', title: "SBG de Ang - Variations 14_15", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FSBG%20de%20Ang%20-%20Variations%2014_15.jpg?alt=media&token=798fc4e9-f82d-4a3c-b424-2d59fbdd9771", free: "false", id: "21", url: "https://cdn.berimbaula.com/sdiv_live%2FSBG%20de%20Ang%20-%20Variations%2014_15.mp4"}
  },{snippet:{host:'cdn', title: "SBG de Ang - Variation 16", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FSBG%20de%20Ang%20-%20Variation%2016.jpg?alt=media&token=71914444-efc8-4ed5-8c9f-02fc59a7f3b6", free: "false", id: "22", url: "https://cdn.berimbaula.com/sdiv_live%2FSBG%20de%20Ang%20-%20Variation%2016.mp4"}
  },{snippet:{host:'cdn', title: "SBG de Ang - Variation 17", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FSBG%20de%20Ang%20-%20Variation%2017.jpg?alt=media&token=cbd8e85a-c111-4658-8db8-abe6a83f5f2d", free: "false", id: "23", url: "https://cdn.berimbaula.com/sdiv_live%2FSBG%20de%20Ang%20-%20Variation%2017.mp4"}
  },{snippet:{host:'cdn', title: "SBG de Ang - Variation 18", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FSBG%20de%20Ang%20-%20Variation%2018.jpg?alt=media&token=0614f92b-8d7b-4c8e-96fa-c23bea46e8b3", free: "false", id: "24", url: "https://cdn.berimbaula.com/sdiv_live%2FSBG%20de%20Ang%20-%20Variation%2018.mp4"}
  },{snippet:{host:'cdn', title: "Slow Pandeiro", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FSlow%20Pandeiro.png?alt=media&token=2d135c4f-e49f-4918-94a7-1f8a0dc504e4", free: "false", id: "25", url: "https://cdn.berimbaula.com/sdiv_live%2FSlow%20Pandeiro.m4v"}
  },{snippet:{host:'cdn', title: "Medium Pandeiro", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FMedium%20Pandeiro.png?alt=media&token=80127dca-3197-47d2-b38e-e77af77e578d", free: "false", id: "26", url: "https://cdn.berimbaula.com/sdiv_live%2FMedium%20Pandeiro.mov"}
},{snippet:{host:'cdn', title: "Fast Pandeiro", thumbnail: "https://firebasestorage.googleapis.com/v0/b/capo-1dea.appspot.com/o/sbmuht_live%2FFast%20Pandeiro.png?alt=media&token=a8c781b3-bc1c-464b-af38-0845d1a18520", free: "false", id: "27", url: "https://cdn.berimbaula.com/sdiv_live%2FFast%20Pandeiro.m4v"}}
  ]
}


export default cdnLessons;
