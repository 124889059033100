import React, { useState, useContext, useEffect } from "react";
import { FlatList, View, StatusBar, StyleSheet, Text, TouchableOpacity, Button, Image, Dimensions } from "react-native";
import { AuthContext } from '../navigation/AuthProvider';
import Loading from '../components/Loading';
import Photo from '../images/lock.png'

import cdnLessons from './temp3'
//import firebaseLessons from './temp2'
import youtubeLessons from './temp'

import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

export default function HomeScreen({navigation}) {
  const { user } = useContext(AuthContext);
  const currentUser = user.uid;
  //var storage = firebase.storage();
  //var storageRef = storage.ref();
  //var listRef = storageRef.child('sdiv_live');
  //var thumbsRef = storageRef.child('sbmuht_live');
  const width = Math.min(Dimensions.get('window').width*.95, 480);


  const db = firebase.firestore();

  //const [data, setData] = useState([]);
  const [activeSub, setActiveSub] = useState(false);
  const [loading, setLoading] = useState(true);
  const [featured, setFeatured] = useState([]);
  //const [weekly, setWeekly] = useState([]);




  useEffect (()=>{
    var w = [];
    var f = [];
    const fetchWeekly = async () => {
      const res = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLfraH43zUWInnHAGmpa66VCvRVOqRjP3R&maxResults=60&key=AIzaSyD52xahyWqhyWQmZM0TiHPJ-vozKpQTmN4`)
      res
        .json()
        .then(res=>{
          w=res.items
          f=w.shift()
          setFeatured(f)
          //setWeekly(w)
          w.forEach(function(i){
            i.snippet.free='false'
            i.snippet.host='youtube'
          })
          youtubeLessons.items.push(...w)
          //firebaseLessons.items.push(...w)
          cdnLessons.items.push(...w)
        })
    }

    const fetchData = async() => {
    const docRef = await db
      .collection('customers')
      .doc(currentUser)
      .collection('subscriptions');

      docRef.get().then(function(querySnapshot) {
        const docCount = querySnapshot.docs.length;
        if (docCount > 0){
            if (querySnapshot.docs[0].data().current_period_end.seconds > Date.now()/1000){
              setActiveSub(true);
              console.log('active')
            }
        }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      }).finally(function(done){
          setLoading(false);
      });
    }

    const fetchDataPayments = async() => {
      const docRefPayment = await db
        .collection('customers')
        .doc(currentUser)
        .collection('payments');

        docRefPayment.get().then(function(querySnapshot) {
          const docCountP = querySnapshot.docs.length;
          if (docCountP > 0){
            setActiveSub(true);
          }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        }).finally(function(done){
            setLoading(false);
        });
    }



    fetchData();
    fetchDataPayments();
    fetchWeekly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
    const checkActive = ( item ) => {
      let url = ''
      if (( item.snippet.free === 'true')|| activeSub) {
        if (item.snippet.host==='youtube'){
            url = 'https://www.youtube.com/watch?v='+item.snippet.resourceId.videoId
        } else {
          url = item.snippet.url;
        }
        navigation.navigate('Player', { url: url  })
      } else {
        alert('Please sign up for a premium plan')
        navigation.navigate ('Profile');
      }
    };

  // function displayImage(imageRef) {
  //   imageRef.getDownloadURL().then(function(url) {
  //     return(url);
  //   }).catch(function(error) {
  //     // Handle any errors
  //   });
  // }

  function playVid (i){
        navigation.navigate('Player', { url: `https://www.youtube.com/watch?v=${i.snippet.resourceId.videoId}` });
  }

  // const getName = (name) => {
  //   name = name.replace('.mp4','')
  //   return name;
  // }
// var tempName = '';
// var arr = 0;
// var tdata = '';

// const getMetaData =  (itemRef) => {
//   itemRef.().then((meta) => {
//     if ('free' in meta.customMetadata) {
//       return 'true'
//     } else {
//       return 'false'
//     }
//   }).catch(function(error){
//     return 'false'
//   });
//}

  // useState(() => {
  //     listRef.listAll().then(function(res) {
  //       res.items.forEach(function(itemRef) {
  //         //tempName = getName(itemRef.name);
  //         itemRef.getMetadata().then(function(meta){
  //         itemRef.getDownloadURL().then(function(url){
  //           thumbsRef.child(getName(itemRef.name)+'.jpg').getDownloadURL().then(function(thumbUrl) {
  //               free = meta.customMetadata.free;
  //               sortorder = meta.customMetadata.sortorder;
  //               setData(data => [...data, {title:getName(itemRef.name), url:url, free: free, id: sortorder, thumb: thumbUrl}]);
  //               });
  //             });
  //           });
  //       });
  //     }).catch(function(error) {
  //       console.log(error)
  //     }).finally(function(error){
  //       setLoading(false)
  //       console.log(data)
  //     });
  //   });



// function checkdate (i){
//   //get users signup date and alert if video not ready yet
//   if (i.id==3){
//       alert('This video is locked until <date>');
//   } else {
//       checkActive();
//       navigation.navigate('Player', { url: i.url });
//   }
// }

const Item = ({ item, onPress, style }) => (
<TouchableOpacity onPress={() => checkActive(item)} style={[styles.item, style]}>
  <View style={styles.imagebox}>
    <View style={styles.listTitle}>
      <Text style={styles.title}>{item.snippet.title}</Text>
      {activeSub ? <View /> : item.snippet.free === 'false' && <Image source={Photo} style={{width: 20, height: 20}} />}
    </View>
    <Image
          style={{width: width,
                  height: (width*828/1465),
                  aspectRatio: 1,
                  marginTop: 2,
                  alignSelf: 'center'}}
          source={{uri: item.snippet.resourceId ? `https://i.ytimg.com/vi/${item.snippet.resourceId.videoId}/hqdefault.jpg` : item.snippet.thumbnail }}
          imageStyle={{resizeMode: 'center' }}
        />
  </View>

</TouchableOpacity>
);

// const Item = ({ item, onPress, style }) => (
// <TouchableOpacity onPress={() => checkActive('youtube',item.free, item.snippet.resourceId.videoId)} style={[styles.item, style]}>
//   <View style={styles.imagebox}>
//     <View style={styles.listTitle}>
//       <Text style={styles.title}>{item.snippet.title}</Text>
//       {activeSub ? <View /> : item.free === 'false' && <Image source={Photo} style={{width: 20, height: 20}} />}
//     </View>
//     <Image
//           style={{width: width,
//                   height: (width*360/480),
//                   aspectRatio: 1,
//                   marginTop: 2,
//                   alignSelf: 'center'}}
//           source={{uri:`https://i.ytimg.com/vi/${item.snippet.resourceId.videoId}/hqdefault.jpg`}}
//           imageStyle={{resizeMode: 'contain' }}
//         />
//   </View>
//
// </TouchableOpacity>
// );

const [selectedId, setSelectedId] = useState(null);

const renderItem = ({ item }) => {
  const backgroundColor = item.id === selectedId ? "#575157" : "#c9c7c7";
  return (
    <Item
      item={item}
      onPress={() => setSelectedId(item.id)}
      style={{ backgroundColor }}
    />
  );
};

const renderHeader = () => {
    return <Text style={styles.sectionTitle}>Lessons</Text>
  };

  if (loading) {
    return <Loading />;
  }

return (
  <View style={styles.container}>
    <Button title="Free Lessons" onPress={() => navigation.navigate('Free Lessons')} />
    <TouchableOpacity
    onPress={() => playVid(featured)}
    style={styles.item}>
      <View style={styles.featured}>
      <Text style={styles.sectionTitle}> Weekly Featured Video </Text>
      <Text style={styles.title}>{featured.snippet.title}</Text>
        <Image
        source={{uri:`https://i.ytimg.com/vi/${featured.snippet.resourceId.videoId}/hqdefault.jpg`}}
          style={{width:width, height:(width*360/480)}} imageStyle={{resizeMode: 'contain' }}
        />
      </View>
    </TouchableOpacity>
    <FlatList
      data={cdnLessons.items}
      renderItem={renderItem}
      keyExtractor={item => item.snippet.id}
      //keyExtractor = { (item, index) => index.toString() }
      extraData={selectedId}
      ListHeaderComponent={renderHeader}
      numColumns={width<400? 1 : 2 }
      columnWrapperStyle={width<400?'':{alignSelf:'center'}}
    />
  </View>
);
};

const styles = StyleSheet.create({
container: {
  flex: 1,
  marginTop: StatusBar.currentHeight || 0,
},
item: {
  padding: 2,
  marginVertical: 2,
  marginHorizontal: 5,

},
listTitle: {
  flexDirection: 'row',
  justifyContent: 'space-between'
},
imagebox:{
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center'
},
title:{
  fontSize: 20,
  padding: 5
},
sectionTitle: {
  fontSize: 25,
  fontWeight: 'Bold'
},
featured: {
  alignItems: 'center'
}
});
