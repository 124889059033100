import React from 'react';
import { StyleSheet, Text, View, Button, Dimensions, ImageBackground, Image} from 'react-native';
import Logo from '../images/white.png'
import Photo from '../images/berimbaula.png'

export default function LandingScreen({navigation}) {


return (


  <View style={styles.container}>
        <View style={styles.topContainer}>

          <Image source={Photo} style={{width: width, height:(width*800)/1280}} imageStyle={{resizeMode: 'contain' }} />
          <Text style={styles.body}>Welcome to Berimbaula. I created this platform to help you improve your berimbau playing and overall musical abilities. With better technique comes more confidence and my hope is that you will be able to express yourself freely. It will take some work but remember to have fun.  -Mestre Muito Tempo</Text>
          <ImageBackground source={Logo} style={{width: width, height: (width*977)/923}} imageStyle={{resizeMode: 'contain' }} >
              <View style={styles.offers}>
                <View style={styles.box}>
                  <Text style={styles.boxtitle}>FREE </Text>
                  <Text style={styles.boxtext}>$0/month</Text>
                  <Text style={styles.boxtext}>3 Premium Classes</Text>
                </View>
                <View style={styles.box}>
                  <Text style={styles.boxtitle}>MONTHLY</Text>
                  <Text style={styles.boxtext}>$29/month</Text>
                  <Text style={styles.boxtext}>Full Access</Text>
                </View>
                <View style={styles.box}>
                  <Text style={styles.boxtitle}>ANNUAL</Text>
                  <Text style={styles.boxtext}>$199/year</Text>
                  <Text style={styles.boxtext}>Only $16/Month</Text>
                </View>
              </View>
          </ImageBackground>
        </View>

        <View>
          <Button
            title="GET STARTED"
            style={{flex:1}}
            onPress={() => navigation.navigate('Login')}
          />
        </View>
      </View>
)

};

const width = Dimensions.get('window').width; //full width
//const height = Dimensions.get('window').height; //full height



  const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor : 'black',
        },
      topContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
      body: {
        padding: 20,
        justifyContent: 'center',
        color: 'white',
        fontSize:  width>600 ? 18 : 15,
      },
      box: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: '#eee',
        padding: 3,
        borderRadius: 5,
        marginHorizontal: '2%'

      },
      boxtext: {
        color: 'grey',
        justifyContent: 'center',
        fontSize:  width>600 ? 18 : 10,

      },
      boxtitle: {
        color: 'grey',
        justifyContent: 'center',
        fontWeight: 'bold',
        borderBottomWidth: 2,
        borderColor: 'black',
        fontSize:  width>600 ? 20 : 15,
      },
      offers: {
        flex: 1,
        flexDirection:'row',
        justifyContent: 'space-around',
        alignItems: 'center'
      }
  });
