import React, { useContext, useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import AuthStack from './AuthStack';
import HomeStack from './HomeStack';
import { AuthContext } from './AuthProvider';
import Loading from '../components/Loading';

import firebase from 'firebase/app';
import 'firebase/auth';


const firebaseConfig = {
   apiKey: "AIzaSyBK_bHsl8m7fOL6MIIfpKydKAYnYgHNgns",
   authDomain: "capo-1dea.firebaseapp.com",
   databaseURL: "https://capo-1dea.firebaseio.com",
   projectId: "capo-1dea",
   storageBucket: "capo-1dea.appspot.com",
   messagingSenderId: "1018649807628",
   appId: "1:1018649807628:web:bd0f09f05764be46acd729",
   measurementId: "G-RD6M0G71QG"
 };

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export default function Routes() {
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [initializing, setInitializing] = useState(true);

  // Handle user state changes
  function onAuthStateChanged(user) {
    setUser(user);
    if (initializing) setInitializing(false);
    setLoading(false);
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <NavigationContainer>
        {user ? <HomeStack/> : <AuthStack />}
    </NavigationContainer>
  );
}
