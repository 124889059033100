import React, { useState, useContext } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ImageBackground} from 'react-native';
import FormButton from '../components/FormButton';
import FormInput from '../components/FormInput';
import { AuthContext } from '../navigation/AuthProvider';

import Photo from '../images/berimbau2018toque.png'

export default function LoginScreen({ navigation }) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  return (
    <ImageBackground source={Photo} style={{width: 'width', minHeight: '100vh'}} imageStyle={{resizeMode: 'center', opacity: .1 }}>
      <View style={styles.container}>
        <Text style={styles.text}>Berimbaula</Text>
        <FormInput
          value={email}
          placeholderText='Email'
          onChangeText={userEmail => setEmail(userEmail)}
          autoCapitalize='none'
          keyboardType='email-address'
          autoCorrect={false}
        />
        <FormInput
          value={password}
          placeholderText='Password'
          onChangeText={userPassword => setPassword(userPassword)}
          secureTextEntry={true}
        />
        <FormButton buttonTitle='Login' onPress={() => login(email, password)} />
        <TouchableOpacity
          style={styles.navButton}
          onPress={() => navigation.navigate('Signup')}
        >
          <Text style={styles.navButtonText}>New user? Join here</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.navButton}
          onPress={() => navigation.navigate('ForgotPassword')}
        >
          <Text style={styles.navButtonText}>Forgot Password</Text>
        </TouchableOpacity>
        </View>
    </ImageBackground>

  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    opacity: 1
  },
  text: {
    fontSize: 24,
    marginBottom: 10
  },
  navButton: {
    marginTop: 15
  },
  navButtonText: {
    fontSize: 20,
    color: '#6646ee'
  }
});
