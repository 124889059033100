const youtubeLessons = {
  "kind": "youtube#playlistItemListResponse",
  "etag": "Fxw7YzEGSUkSvbOFl9uNTJiY39o",
  "items": [
    {
      "free": 'true',
      "kind": "youtube#playlistItem",
      "etag": "UDNjOxy11UgtgcvDSo56Fo3mrUQ",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS41MjE1MkI0OTQ2QzJGNzNG",
      "snippet": {
        "publishedAt": "2021-01-06T23:39:54Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Intro",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/_m-1lo7p2Pg/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/_m-1lo7p2Pg/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/_m-1lo7p2Pg/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/_m-1lo7p2Pg/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/_m-1lo7p2Pg/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 0,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "_m-1lo7p2Pg"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "EOyIUTj0BpXIHlcgRHdhmWNa8qM",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS4yODlGNEE0NkRGMEEzMEQy",
      "snippet": {
        "publishedAt": "2021-01-06T23:30:19Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Berimbau 101",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/E3afbMsJSGw/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/E3afbMsJSGw/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/E3afbMsJSGw/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/E3afbMsJSGw/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/E3afbMsJSGw/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 1,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "E3afbMsJSGw"
        }
      }
    },
    {
      "free": 'true',
      "kind": "youtube#playlistItem",
      "etag": "9agFEhufX6nk0rxEsz1vurhUsW0",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS4wMTcyMDhGQUE4NTIzM0Y5",
      "snippet": {
        "publishedAt": "2021-01-06T23:34:20Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Berimbau Notes",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/raxtCSeIXlw/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/raxtCSeIXlw/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/raxtCSeIXlw/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/raxtCSeIXlw/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/raxtCSeIXlw/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 2,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "raxtCSeIXlw"
        }
      }
    },
    {
      "free": 'true',
      "kind": "youtube#playlistItem",
      "etag": "SS6cq4Ly1YGLOdvPbs2hvfQ0bw4",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS4xMkVGQjNCMUM1N0RFNEUx",
      "snippet": {
        "publishedAt": "2021-01-07T04:14:38Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Exercise 1",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/C5QAJKKDbsw/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/C5QAJKKDbsw/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/C5QAJKKDbsw/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/C5QAJKKDbsw/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/C5QAJKKDbsw/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 3,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "C5QAJKKDbsw"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "xa28gHIHwVrzUqNZjmiz32mJEj4",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS41MzJCQjBCNDIyRkJDN0VD",
      "snippet": {
        "publishedAt": "2021-01-07T04:40:24Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Exercise 2",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/iwR6rOFDRWI/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/iwR6rOFDRWI/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/iwR6rOFDRWI/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/iwR6rOFDRWI/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/iwR6rOFDRWI/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 4,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "iwR6rOFDRWI"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "tBLfQnIvrWmdMgPi3kM8fWDlPXA",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS5DQUNERDQ2NkIzRUQxNTY1",
      "snippet": {
        "publishedAt": "2021-01-07T04:45:45Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Exercise 3",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/hC-C4NPqdQQ/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/hC-C4NPqdQQ/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/hC-C4NPqdQQ/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/hC-C4NPqdQQ/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/hC-C4NPqdQQ/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 5,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "hC-C4NPqdQQ"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "TwnabU6f45AHY7hmnCVt1nSXsV0",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS45NDk1REZENzhEMzU5MDQz",
      "snippet": {
        "publishedAt": "2021-01-07T04:46:18Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Exercise 4",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/wxHrCZz_grU/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/wxHrCZz_grU/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/wxHrCZz_grU/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/wxHrCZz_grU/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/wxHrCZz_grU/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 6,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "wxHrCZz_grU"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "tLWn0tllnpIB-HUObCh0XZ49yFA",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS5GNjNDRDREMDQxOThCMDQ2",
      "snippet": {
        "publishedAt": "2021-01-07T04:48:05Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Exercise 5",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/j52brHqjpqo/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/j52brHqjpqo/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/j52brHqjpqo/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/j52brHqjpqo/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/j52brHqjpqo/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 7,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "j52brHqjpqo"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "ostw7CLA5DMg8zYlSD8R4TD_jUk",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS4wOTA3OTZBNzVEMTUzOTMy",
      "snippet": {
        "publishedAt": "2021-01-07T03:59:52Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Toque : Angola",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/mA6ngtK2qjo/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/mA6ngtK2qjo/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/mA6ngtK2qjo/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/mA6ngtK2qjo/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/mA6ngtK2qjo/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 8,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "mA6ngtK2qjo"
        }
      }
    },
    {
      "free": 'true',
      "kind": "youtube#playlistItem",
      "etag": "15PJoIHI_bvi8cnHbjyPAZ1PEnI",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS41QTY1Q0UxMTVCODczNThE",
      "snippet": {
        "publishedAt": "2021-01-07T15:08:58Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variations 1/2 (basic but essential)",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/JW3EFjbh18o/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/JW3EFjbh18o/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/JW3EFjbh18o/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/JW3EFjbh18o/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/JW3EFjbh18o/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 9,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "JW3EFjbh18o"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "RX7ln79VEzENkL06a5NLsBMCMec",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS4zMDg5MkQ5MEVDMEM1NTg2",
      "snippet": {
        "publishedAt": "2021-01-07T04:55:28Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variation 3",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/iqJ6vu4BJq0/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/iqJ6vu4BJq0/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/iqJ6vu4BJq0/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/iqJ6vu4BJq0/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/iqJ6vu4BJq0/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 10,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "iqJ6vu4BJq0"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "zJAlB9ofEb5HrXcbMZGlP_hRorw",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS41Mzk2QTAxMTkzNDk4MDhF",
      "snippet": {
        "publishedAt": "2021-01-07T04:56:42Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variation 4/5",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/ITcWDu62kys/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/ITcWDu62kys/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/ITcWDu62kys/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/ITcWDu62kys/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/ITcWDu62kys/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 11,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "ITcWDu62kys"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "RoMG2p0H_BT0rk62AxQek2zT5A4",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS5EQUE1NTFDRjcwMDg0NEMz",
      "snippet": {
        "publishedAt": "2021-01-07T05:04:48Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variations 5/6",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/NtBekSFUfko/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/NtBekSFUfko/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/NtBekSFUfko/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/NtBekSFUfko/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/NtBekSFUfko/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 12,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "NtBekSFUfko"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "Mc1v8_iU-qHVYZfqBzvPIYsPeA8",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS4yMUQyQTQzMjRDNzMyQTMy",
      "snippet": {
        "publishedAt": "2021-01-07T16:49:26Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variation 7",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/7I-v_NPi6VI/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/7I-v_NPi6VI/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/7I-v_NPi6VI/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/7I-v_NPi6VI/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/7I-v_NPi6VI/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 13,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "7I-v_NPi6VI"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "stN0Onc6z611wyO7Pgcicn7oGYA",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS45RTgxNDRBMzUwRjQ0MDhC",
      "snippet": {
        "publishedAt": "2021-01-07T16:50:59Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variation 8",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/Ic-WmuW6vgk/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/Ic-WmuW6vgk/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/Ic-WmuW6vgk/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/Ic-WmuW6vgk/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/Ic-WmuW6vgk/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 14,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "Ic-WmuW6vgk"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "l-LkE8TxLKRTe8OPWWwSfAAkEFM",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS5EMEEwRUY5M0RDRTU3NDJC",
      "snippet": {
        "publishedAt": "2021-01-07T04:52:17Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Toque: Sao Bento Pequeno",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/Csh0A-oCppc/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/Csh0A-oCppc/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/Csh0A-oCppc/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/Csh0A-oCppc/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/Csh0A-oCppc/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 15,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "Csh0A-oCppc"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "7Oj9uSW5Kc1yGbXKtBJ4kbqvqi4",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS5ENDU4Q0M4RDExNzM1Mjcy",
      "snippet": {
        "publishedAt": "2021-01-07T16:51:45Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variations 9/10 : São Bento Pequeno",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/f_UffVpGy34/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/f_UffVpGy34/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/f_UffVpGy34/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/f_UffVpGy34/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/f_UffVpGy34/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 16,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "f_UffVpGy34"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "ylw8HmWMGVoVkoBteYq1H9Ye3hk",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS4zRjM0MkVCRTg0MkYyQTM0",
      "snippet": {
        "publishedAt": "2021-01-07T18:43:25Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variations 11/12 : São Bento Pequeno",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/c7zuc9EwhLc/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/c7zuc9EwhLc/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/c7zuc9EwhLc/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/c7zuc9EwhLc/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/c7zuc9EwhLc/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 17,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "c7zuc9EwhLc"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "_hBSU7tigMqdAkFZxMC_MF8dZnw",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS45NzUwQkI1M0UxNThBMkU0",
      "snippet": {
        "publishedAt": "2021-01-07T18:45:10Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variation 13 : São Bento Pequeno",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/uU29Znbvw8Y/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/uU29Znbvw8Y/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/uU29Znbvw8Y/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/uU29Znbvw8Y/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/uU29Znbvw8Y/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 18,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "uU29Znbvw8Y"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "jcHDUaix1wFo4eyH2Uj5_-1Tdf4",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS40NzZCMERDMjVEN0RFRThB",
      "snippet": {
        "publishedAt": "2021-01-07T04:50:57Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Toque: Sao Bento Grande de Angola",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/EXBoh25MpvE/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/EXBoh25MpvE/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/EXBoh25MpvE/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/EXBoh25MpvE/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/EXBoh25MpvE/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 19,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "EXBoh25MpvE"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "fDZLuoR_LsmNA1qJaRAXPacRg_M",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS5DNzE1RjZEMUZCMjA0RDBB",
      "snippet": {
        "publishedAt": "2021-01-07T18:46:15Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variations 14/15 : São Bento Grande de Angola",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/51z6IjOBzYE/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/51z6IjOBzYE/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/51z6IjOBzYE/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/51z6IjOBzYE/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/51z6IjOBzYE/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 20,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "51z6IjOBzYE"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "v4CUZfgmXtuTyx4XWLui15mq9I0",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS43MTI1NDIwOTMwQjIxMzNG",
      "snippet": {
        "publishedAt": "2021-01-07T18:47:27Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variation 16 : São Bento Grande de Angola",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/IL01Si_fPJc/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/IL01Si_fPJc/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/IL01Si_fPJc/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/IL01Si_fPJc/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/IL01Si_fPJc/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 21,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "IL01Si_fPJc"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "2uvkzielgGU9YljP2UoLG207SwA",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS5DQ0MyQ0Y4Mzg0M0VGOEYw",
      "snippet": {
        "publishedAt": "2021-01-07T18:48:30Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Variation 17 : São Bento Grande de Angola",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/NfSceEF1O6M/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/NfSceEF1O6M/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/NfSceEF1O6M/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/NfSceEF1O6M/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/NfSceEF1O6M/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 22,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "NfSceEF1O6M"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "jFguPWqHQ7dl_TJ5Z-nWmcxTDyI",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS41NkI0NEY2RDEwNTU3Q0M2",
      "snippet": {
        "publishedAt": "2021-01-06T23:17:17Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "São Bento Grande de Angola: Variation 18",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/UpZtQE46dTM/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/UpZtQE46dTM/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/UpZtQE46dTM/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/UpZtQE46dTM/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/UpZtQE46dTM/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 23,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "UpZtQE46dTM"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "ouDRwMO1aeRK8En2JUH8L76Q01g",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS44Mjc5REFBRUE2MTdFRDU0",
      "snippet": {
        "publishedAt": "2021-01-07T19:38:11Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Pandeiro Slow",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/5c9ln2splcA/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/5c9ln2splcA/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/5c9ln2splcA/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/5c9ln2splcA/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/5c9ln2splcA/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 24,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "5c9ln2splcA"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "boYCv1dKynntGEP_jg5pfnyOP00",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS5DMkU4NTY1QUFGQTYwMDE3",
      "snippet": {
        "publishedAt": "2021-01-07T19:34:47Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Medium Pandeiro",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/72M75ofBH6s/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/72M75ofBH6s/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/72M75ofBH6s/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/72M75ofBH6s/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/72M75ofBH6s/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 25,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "72M75ofBH6s"
        }
      }
    },
    {
      "free": 'false',
      "kind": "youtube#playlistItem",
      "etag": "UqtA8pHL6Tjh-dO34IQ3RSIUbmk",
      "id": "UExmcmFINDN6VVdJa0t1LTZVXzVrVnZDdmhoSkpaLWktTS4yQUE2Q0JEMTk4NTM3RTZC",
      "snippet": {
        "publishedAt": "2021-01-07T19:25:06Z",
        "channelId": "UCc4bNJs8ey8a8Y1kPE_VvdA",
        "title": "Pandeiro Fast",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/SvfIheksu94/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/SvfIheksu94/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/SvfIheksu94/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/SvfIheksu94/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/SvfIheksu94/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "joga muito capoeira",
        "playlistId": "PLfraH43zUWIkKu-6U_5kVvCvhhJJZ-i-M",
        "position": 26,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "SvfIheksu94"
        }
      }
    }
  ],
  "pageInfo": {
    "totalResults": 27,
    "resultsPerPage": 30
  }
}



export default youtubeLessons;
