import React, { useContext, useState, useEffect } from 'react';

import { StyleSheet,
  View,
  Text,
  Dimensions,
  Button} from 'react-native';
import { AuthContext } from '../navigation/AuthProvider';
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import Loading from '../components/Loading';

import {loadStripe} from '@stripe/stripe-js';



import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/functions'

export default function ProfileScreen(props) {
  const db = firebase.firestore();
  //const stripe = loadStripe('pk_live_51HgFrCBtVdxOxnm3gftDK2xQdSbcqZVCWNrp2hnInRM5wC6dhC1zQVZ9YWl7FGkWnnYYq3IxJmK4KwgBcQcakABg00BbSECCCJ');
  const [loading, setLoading] = useState(true);

  //const link = props.route.params;
  const { user } = useContext(AuthContext);

  // const width = Dimensions.get('window').width; //full width
  // const height = Dimensions.get('window').height; //full height

  const currentUser = user.uid;

  const convertDate = (t) => {
    var d = new Date();
    d.setTime(t);
    const dt = d.getMonth()+1 + "/" + d.getDate() + "/" +    d.getFullYear()
    return dt;
  }

  const portal = async () => {
    setLoading(true)
    const functionRef = firebase
    .app()
    .functions('us-west2')
    .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);

  }

  const [isActive, setIsActive] = useState(false);
  const [currentSubStart, setCurrentSubStart] = useState('');
  const [currentSubRenew, setCurrentSubRenew] = useState('');
  const [currentSubProduct, setCurrentSubProduct] = useState('');

  useEffect(() => {

      const fetchData = async() => {
          const docRef = await db
          .collection('customers')
          .doc(currentUser)
          .collection('subscriptions')

          docRef.get().then(function(querySnapshot) {
                // docCount = querySnapshot.docs.length;
                const temp = querySnapshot.docs[0].data();
                 setCurrentSubRenew(convertDate(temp.current_period_end.seconds*1000));
                 setCurrentSubStart(convertDate(temp.created.seconds*1000));
                 setCurrentSubProduct(temp.status);
                if (querySnapshot.docs[0].data().current_period_end.seconds > Date.now()/1000) {
                  setIsActive(true);
                }
              })
              .catch(function(error) {
                  setIsActive(false);
              });
              setLoading(false)
            }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


const subscribe = async ( prod, price ) => {
  setLoading(true)
  let docRef = '';
  if (prod == 'Lifetime') {
    docRef = await db
    .collection('customers')
    .doc(currentUser)
    .collection('checkout_sessions')
    .add({
      price: price,
      mode: 'payment',
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      product: prod,
      metadata: {
        tax_rate: '10% sales tax exclusive',
      },
    });
  } else {
    docRef = await db
    .collection('customers')
    .doc(currentUser)
    .collection('checkout_sessions')
    .add({
      price: price,
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      product: prod,
      metadata: {
        tax_rate: '10% sales tax exclusive',
      },
    });
  }


  docRef.onSnapshot(async (snap) => {
    const { error, sessionId } = snap.data();
    if (error) {
      // Show an error to your customer and then inspect your function logs.
      alert(`An error occured: ${error.message}`);
      //document.querySelectorAll('button').forEach((b) => (b.disabled = false));
    }
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe =  await loadStripe('pk_live_51HgFrCBtVdxOxnm3gftDK2xQdSbcqZVCWNrp2hnInRM5wC6dhC1zQVZ9YWl7FGkWnnYYq3IxJmK4KwgBcQcakABg00BbSECCCJ');
      //const stripe = Stripe(STRIPE_PUBLISHABLE_KEY);
      stripe.redirectToCheckout({ sessionId });

    }
  });
}

  if (loading) {
    return <Loading />;
  }

  return (

    <View style={styles.container}>
    <View style={styles.box}>
      <Text>Email: </Text>
      <Text>{user.email}</Text>
    </View>
      { isActive ?
      <View>
        <View style={styles.box}>
          <Text>Start Date: </Text>
          <Text>{currentSubStart}</Text>
        </View>
        <View style={styles.box}>
          <Text>Auto Renewal: </Text>
          <Text>{currentSubRenew}</Text>
        </View>
        <View style={styles.box}>
          <Text>Premium Plan Status: </Text>
          <Text style={styles.plan}>{currentSubProduct}</Text>
        </View>
        <Button onPress={()=> portal()} title = "Cancel My Subscription" />
      </View>
      :
      <PricingTable>
        <PricingSlot onClick={() => subscribe ('Monthly','price_1HpzrjBtVdxOxnm3WwTNnijZ')} title='MONTHLY' buttonText='Get Started' priceText='$29/month'>
        <PricingDetail>Full Access</PricingDetail>
        </PricingSlot>
        <PricingSlot onClick={() => subscribe ('Annual','price_1HpzrkBtVdxOxnm33k055cOJ')} highlighted title='ANNUAL' buttonText='Get Started' priceText='$199/year'>
        <PricingDetail>Only $16/Month</PricingDetail>
        </PricingSlot>
        <PricingSlot onClick={() => subscribe ('Lifetime','price_1L8f31BtVdxOxnm3Gj6Nlkw7')} highlighted title='LIFETIME' buttonText='Get Started' priceText='$300'>
        <PricingDetail>Limited Time Only</PricingDetail>
        </PricingSlot>
      </PricingTable>
    }
    </View>
  )
};



  const styles = StyleSheet.create({
    container: {
    flex: 1,
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height
    },
    box: {
      flexDirection: 'row',
      padding: 5
    },
    image: {
      flex: 1,
      justifyContent: "center",
      width: '100%'
    },
    logout: {
      justifyContent: "center"
    },
    plan: {
      textTransform: "capitalize"
    }
  });
