import React, { useState, useEffect } from "react";
import { FlatList, View, StatusBar, StyleSheet, Text, TouchableOpacity, Image, Dimensions } from "react-native";
// import { AuthContext } from '../navigation/AuthProvider';

export default function FreeScreen({navigation}) {

  // const { user } = useContext(AuthContext);
  const [card, setCard] = useState([]);
  const [selectedId, setSelectedId] = useState(null);


  async function fetchData () {
    const res = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLfraH43zUWInushEEvoytpCKkfyIAvh4f&maxResults=60&key=AIzaSyD52xahyWqhyWQmZM0TiHPJ-vozKpQTmN4`)
    res
      .json()
      .then(res=>{
        setCard(res.items)
        //console.log(res);
      })
  }
    useEffect(() => {
      fetchData();
    },[]);



function playVid (i){
      navigation.navigate('Player', { url: `https://www.youtube.com/watch?v=${i.snippet.resourceId.videoId}` });
}


const width = Math.min(Dimensions.get('window').width, 480); //full width


const Item = ({ item, onPress, style }) => (
<TouchableOpacity
onPress={() => playVid(item)}
style={[styles.item, style]}>
  <View>
    <Image
    source={{uri:`https://i.ytimg.com/vi/${item.snippet.resourceId.videoId}/hqdefault.jpg`}}
      style={{width:width, height:(width*360/480)}} imageStyle={{resizeMode: 'contain' }}
    /><Text style={styles.title}>{item.snippet.title}</Text>
  </View>
</TouchableOpacity>
);


const renderItem = ({ item }) => {
  const backgroundColor = item.id === selectedId ? "#575157" : "#c9c7c7";
  return (
    <Item
      item={item}
      onPress={() => setSelectedId(item.snippet.resourceId.videoId)}
      style={{ backgroundColor }}
    />
  );
};

return (
  <View style={styles.container}>
    <FlatList
      data={card}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      //keyExtractor = { item => item.resourceId.videoId }
      extraData={selectedId}
    />
  </View>
);
};


const styles = StyleSheet.create({
container: {
  flex: 1,
  marginTop: StatusBar.currentHeight || 0,
  alignItems: 'center'
},
item: {
  padding: 2,
  marginVertical: 3,
  marginHorizontal: 1,
  flexDirection:'row',
},
title: {
  fontSize: 18
},
image: {
  //width: width,
  //height: (width*360/480),
  width: 100,
  height: 100,
  alignSelf: 'stretch',
}
});
