import React from 'react';
import { Dimensions} from 'react-native';
// import { AuthContext } from '../navigation/AuthProvider';
//import VideoPlayer from 'react-video-js-player';
import ReactPlayer from 'react-player'
//import Loading from '../components/Loading';

export default function PlayerScreen(props) {
//  const [loading, setLoading] = useState(true);
//  const [data, setData] = useState('');

  const link = props.route.params;
  // const { user } = useContext(AuthContext);
  const width = Dimensions.get('window').width; //full width
  //const height = Dimensions.get('window').height; //full height

  return (
    <ReactPlayer url={link.url}
                width = {width}
                controls={true}
                playing={true}
                onContextMenu={e => e.preventDefault()}
                config={{ file: { attributes: { controlsList: 'nodownload', disablePictureInPicture: true, preload:"none"  }}}}/>
  )
};
