import React, { useContext } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from '../screens/HomeScreen';
import PlayerScreen from '../screens/PlayerScreen';
import ProfileScreen from '../screens/ProfileScreen';
import FreeScreen from '../screens/FreeScreen';
import { Button } from 'react-native';

import { AuthContext} from './AuthProvider';


const Stack = createStackNavigator();



// function getHeaderTitle(route) {
//   const routeName = route.state
//     ? route.state.routes[route.state.index].name
//     : route.params?.screen || 'Not Found'
//
//   switch (routeName) {
//     case 'HomeSreen':
//       return 'Home'
//     case 'Profile':
//       return 'Profile'
//   }
// }



function HomeStack() {
const { logout } = useContext(AuthContext);

  return (
      <Stack.Navigator initialRouteName='Premium Lessons'>
        <Stack.Screen name='Free Lessons' component={FreeScreen} />
        <Stack.Screen name='Premium Lessons' component={HomeScreen}
        options={({ navigation }) => ({
          headerRight: () => (
            <Button style ={{marginRight: 10 }}
              onPress={() => {navigation.navigate('Profile')}}
              title="Profile"
            />
          )
        })}
        />
        <Stack.Screen name='Player' component={PlayerScreen} />
        <Stack.Screen name='Profile' component={ProfileScreen} options={({ navigation }) => ({
          headerRight: () => (
            <Button style ={{marginRight: 10 }}
              onPress={() => {logout()}}
              title="Log Out"
            />
          )
        })}
        />
      </Stack.Navigator>
  )
}

export default HomeStack
